import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../components/pageLayout'

// import BlogHeader from '../components/blog_header'
const MEDIUM_SPACING = '0.75rem'

// TODO: use flex and distributed spacing instead of all this margins
const styles = {
  blogPreview: {
    marginBottom: '2rem',
  },
  blogTitleLink: {
    fontSize: '1.5rem',
    //  margin: "5px auto",
    marginBottom: MEDIUM_SPACING,
    fontWeight: 'bold',
  },
  date: {
    fontSize: '1rem',
    fontStyle: 'italic',
    marginBottom: MEDIUM_SPACING,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  excerpt: {
    // margin: `${MEDIUM_SPACING} auto`,
  },
}

// TODO: also put a preview here
function BlogList({ edges }) {
  return edges.map((edge) => {
    const { frontmatter, excerpt } = edge.node
    const { path, title, description } = frontmatter
    // date

    // excerpt is generated by allMarkdownRemark which is the first line? of the article
    // description is custom text we add to frontmatter

    const teaser = description || excerpt

    return (
      <div key={path} style={styles.blogPreview}>
        <div style={styles.blogTitleLink}>
          <Link to={path}>{title}</Link>
        </div>
        {/* FIXME: HIDE FOR NOW - because it shows age of the posts 😢 */}
        {/* <div style={styles.date}>{date}</div> */}
        <div style={styles.excerpt}>{teaser}</div>
      </div>
    )
  })
}

function BlogLayout({ data }) {
  // console.log(props)
  return (
    <PageLayout>
      {/* <BlogHeader /> */}
      <div style={styles.container}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          {/* TODO: make a component */}
          <h4 style={{ marginRight: '1rem' }}>
            <Link to="/tags">Browse by Tag</Link>
          </h4>
          <h4>Latest first</h4>
        </div>
        <hr />
        {/* <div>{data.allMarkdownRemark.totalCount} posts</div> */}
        <BlogList edges={data.allMarkdownRemark.edges} />
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query BlogListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            date(formatString: "MMM DD, YYYY")
            description
          }
          excerpt
        }
      }
    }
  }
`

export default BlogLayout
